/*
* Side Menu
*/

import Menu from './components/Menu.vue';
import Green from './components/Green.vue';
import { createApp, h } from 'vue';

var theme = document.querySelector('body').getAttribute('data-theme-name');


const app = createApp({
    render: () => h(theme === "Green" ? Green : Menu)
});

let newItem = $('<div class="testmenu"></div>');
$('#side-menu-component').append(newItem);

app.mount('#side-menu-component');

String.prototype.beginsWith = function (string) {
    return (this.indexOf(string) === 0);
};
