<template>
  <!-- <button class="mobile-toggle" @click="toggleMobileMenu" aria-label="Toggle menu">
    <i :class="mobileMenuOpen ? 'fas fa-times' : 'fas fa-bars'" class="toggle-icon"></i>
  </button> -->

  <div class="side-menu" id="side-menu">
    <!-- Mobile Toggle Button - Always visible on mobile -->

    <!-- Overlay for mobile -->
    <div v-if="mobileMenuOpen" class="mobile-overlay" @click="closeMobileMenu"></div>

    <!-- Sidebar Container -->
    <div class="sidebar-container" >
      <!-- First Column: Menu -->
      <div class="sidebar-module" ref="modulesContainerRef">
        <div
          v-for="(module, index) in modules_list"
          :key="index"
          ref="moduleItemsRef"
          :class="{ active: active_module?.name === module.name }"
          class="g-module-item"
          @click="open_module(module)"
        >
          <div class="g-module-item-content">
            <span class="g-module-icon">
              <i :class="module_icon[module.name] || 'fas fa-file-alt'"></i>
            </span>
            <span class="g-module-title">{{ module.label ? module.label : module.name }}</span>
          </div>
        </div>
        <div class="scroller-place"></div>
      </div>

      <!-- Second Column: Items with Title -->
      <div class="sidebar-content side-menu-items">
        <div class="content-header">
          <h2 class="content-title">{{ active_module?.name }}</h2>
        </div>

        <div class="content-groups" v-if="selectedModuleItems && selectedModuleItems.length">
          <div
            v-for="(group, groupIndex) in selectedModuleItems"
            :key="groupIndex"
            class="content-group"
          >
            <div
              class="group-header"
              @click="toggleGroup(groupIndex)"
              :class="{ 'group-open': openGroups[groupIndex] }"
            >
              <span>{{ group.label }}</span>
              <i
                :class="openGroups[groupIndex] ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"
                class="group-icon"
              ></i>
            </div>
            <div class="group-items" :class="{ 'group-items-open': openGroups[groupIndex] }">
              <div v-for="(link, itemIndex) in group.links" :key="itemIndex" class="g-content-link">
                <a :href="generate_route(event, link)" :data-label="link.link_to">{{
                  '- ' + link.label
                }}</a>
              </div>

              <!-- <div
                v-for="(link, itemIndex) in group.links"
                :key="itemIndex"
                class="g-content-link"
                @click="navigateToLink(link)"
              >
                <span class="link-text">{{ link.label }}</span>
              </div> -->
            </div>
          </div>
        </div>
        <div v-else class="empty-state">Select a module item</div>
      </div>
    </div>
  </div>
</template>
<!-- 
route ['Workspaces', 'Home']
current_doctype home
current_page div page-workspace
active_module obj home 
-->

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue'

// const route = ref(frappe.get_route())
const route = ref(null)
// const is_rtl = ref(frappe.utils.is_rtl())
const is_rtl = ref(null)
const theme_settings = ref({})
const current_doctype = ref(
  route.value && route.value.length >= 2 && route.value[1] ? route.value[1] : ''
)
const current_page = ref('')
const is_dashboard = ref(false)
const is_shown_mobile_menu = ref(false)
const menu_items_animate = ref('slideInLeft')
const openGroups = ref({})
const mobileMenuOpen = ref(false)
const modules_list = ref([])
const module_items_list = ref({})
const active_module = ref({})
const baseURL = 'https://fm.metasys.store/'

const modulesContainerRef = ref(null)
const moduleItemsRef = ref([])

const module_icon = ref({
  Home: 'fas fa-home',
  Accounting: 'fas fa-dollar-sign',
  Agriculture: 'fas fa-tractor',
  Assets: 'fas fa-archive',
  Build: 'fas fa-tools',
  Buying: 'fas fa-shopping-cart',
  CRM: 'fas fa-users',
  Customization: 'fas fa-sliders-h',
  Education: 'fas fa-graduation-cap',
  Healthcare: 'fas fa-first-aid',
  HR: 'fas fa-users',
  Integrations: 'fas fa-plug',
  Loans: 'fas fa-money-check-alt',
  'Loan Management': 'fas fa-money-check-alt',
  Manufacturing: 'fas fa-industry',
  'Non Profit': 'fas fa-hands-helping',
  Payroll: 'fas fa-file-invoice-dollar',
  Projects: 'fas fa-project-diagram',
  Quality: 'fas fa-shield-alt',
  Retail: 'fas fa-store',
  Selling: 'fas fa-shopping-bag',
  Stock: 'fas fa-box',
  Support: 'fas fa-headset',
  Tools: 'fas fa-wrench',
  Users: 'fas fa-user-friends',
  Website: 'fas fa-globe',
  Settings: 'fas fa-cog',
  Utilities: 'fas fa-tools',
  Payables: 'fas fa-file-invoice-dollar',
  Receivables: 'fas fa-credit-card',
  "Shift & Attendance": 'fas fa-clock',
  ESS: 'fas fa-user',
  "MSM ESS": 'fas fa-user',
  "MSM HR": 'fas fa-users',
  "Payroll & Loan": 'fas fa-file-invoice-dollar',
  "MSM Payroll & Loan": 'fas fa-file-invoice-dollar',
  "Expense Claims": 'fas fa-receipt',
  "Performance & Reviews": 'fas fa-chart-line',
  "Performance": 'fas fa-chart-line',
  "Reviews": 'fas fa-star',
  "Leaves": 'fas fa-calendar-check',
  "Leaves & Attendance": 'fas fa-calendar-check',
  "Employee Lifecycle & Recruitment": 'fas fa-sync-alt',
  "Employee Lifecycle": 'fas fa-sync-alt',
  "Recruitment": 'fas fa-user-plus',
})

const get_modules = (callback) => {
  frappe.call({
    type: 'POST',
    method: 'frappe.desk.desktop.get_workspace_sidebar_items',
    args: {},
    callback
  })
}

const get_module_items = (module = '', callback) => {
  

  frappe.call({
    type: 'POST',
    method: 'frappe.desk.desktop.get_desktop_page',
    args: { page: `{"name":"${module}","title":"${module}"}` },
    callback
  })
}

const module_menu_list = (current_module, is_mobile) => {
  const routeVal = frappe.get_route()
  let _module = current_module

  if (!is_mobile && routeVal?.[0] === 'Workspaces') {
    _module = routeVal[1]
  }

  if (modules_list.value.length) {
    active_module.value = modules_list.value.find((module) => module.name === _module)

    if (active_module.value?.name) {
      if (module_items_list.value[active_module.value.name]?.length) {
        after_side_menu_items()
      } else {
        get_module_items(active_module.value.name, (items) => {
          if (items.message?.cards?.items) {
            module_items_list.value[active_module.value.name] = items.message.cards.items
          }
          after_side_menu_items()
        })
      }
      after_side_menu()
    }
  } else {
    get_modules((result) => {
      modules_list.value = result.message.pages
      active_module.value = modules_list.value.find((module) => module.name === _module)
      // $('.splash').hide()

      if (active_module.value?.name) {
        if (module_items_list.value[active_module.value.name]?.length) {
          after_side_menu_items()
        } else {
          get_module_items(active_module.value.name, (items) => {
            if (items.message?.cards?.items) {
              module_items_list.value[active_module.value.name] = items.message.cards.items
            }
            after_side_menu_items()
          })
        }
        after_side_menu()
      }
    })
  }

}

const after_side_menu = () => {
  return

  current_page.value = $('.content.page-container:visible', document)
}

const after_side_menu_items = () => {
  const route = frappe.get_route()
  current_doctype.value = route && route.length >= 2 && route[1] ? route[1] : ''
  return
}

const get_module_name_from_doctype = () => {
  const route = frappe.get_route()
  current_doctype.value = route && route.length >= 2 && route[1] ? route[1] : ''
  if (route && route[0] === 'dashboard-view') {
    current_doctype.value = 'Dashboard'
  }
  is_dashboard.value = route && route.length === 2 && route[1] && route[0] === 'dashboard-view'

  frappe.call({
    type: 'POST',
    method: 'datavalue_theme_14.api.get_module_name_from_doctype',
    args: {
      doc_name: current_doctype.value,
      current_module: active_module.value?.name || ''
    },
    callback(response) {
      let current_module = response?.message?.[0]?.module || localStorage.getItem('current_page')

      is_shown_mobile_menu.value = is_mobile()
      module_menu_list(current_module)
    }
  })
}

const get_theme_settings = (callback) => {
  if (Object.keys(theme_settings.value).length) {
    callback()
  } else {


    frappe.call({
      type: 'POST',
      method: 'datavalue_theme_14.api.get_theme_settings',
      args: {},
      callback(response) {
        theme_settings.value = response.message
        callback()
      }
    })
  }
}

const generate_route = (event, link) => {
  // event.stopPropagation()

  const opts = {
    name: link.link_to,
    type: link.link_type,
    is_query_report: link.is_query_report
  }
  if (link.link_type === 'Report' && !link.is_query_report) {
    opts.doctype = link.dependencies
  }
  return frappe.utils.generate_route(opts)
}

const open_module = (_module, is_mobile = false) => {
  active_module.value = _module

  // Reset group states when changing menu
  // Only open the first group by default
  if (modules_list[_module.name]) {
    const newOpenGroups = {}
    newOpenGroups[0] = true // Open first group
    openGroups.value = newOpenGroups
  }

  setTimeout(() => module_menu_list(_module.name, is_mobile), 100)
  if (!is_mobile) {
    if ($('body').data('menu-opening-type') === 'Dashboard') {
      frappe.set_route(`/dashboard-view/${_module.name}`)
    } else {
      frappe.set_route(`/${_module.name.replace(/ /g, '-').toLowerCase()}`)
    }
    $('.btn-toggle-main-menu').addClass('menu-shown')
    $('body').removeClass('hide-main-menu')
  } else {
    is_shown_mobile_menu.value = true
  }
}

const is_mobile = () => /android|iphone|ipad|ipod|windows phone/i.test(navigator.userAgent)

get_theme_settings(get_module_name_from_doctype)

const menuItemsVisible = ref(true)
const toggleMenu = () => {
  menuItemsVisible.value = !menuItemsVisible.value

  const sideMenu = document.getElementById("side-menu");
  if (!menuItemsVisible.value) {
    document.body.classList.add('hide-main-menu')
    sideMenu.style.display = "block";
  } else {
    document.body.classList.remove('hide-main-menu')
    sideMenu.style.display = "block";
  }
}
const addExternalButton = () => {
  const waitForButton = () => {
    const interval = setInterval(() => {
      const button = document.querySelector('.btn-toggle-sidebar-menu')

      if (button) {
        button.className = 'btn-toggle-sidebar-menu'
        button.onclick = toggleMenu

        button.addEventListener('mouseover', () => {
          const icon = button.querySelector('i.far')
          if (icon) {
            if (menuItemsVisible.value) {
              icon.classList.remove('fa-bars', 'fa-chevron-double-right')
              icon.classList.add('fa-chevron-double-left')
            } else {
              icon.classList.remove('fa-bars', 'fa-chevron-double-left')
              icon.classList.add('fa-chevron-double-right')
            }
          }
        })

        button.addEventListener('mouseout', () => {
          const icon = button.querySelector('i.far')
          if (icon) {
            icon.classList.remove('fa-chevron-double-left', 'fa-chevron-double-right')
            icon.classList.add('fa-bars')
          }
        })

        clearInterval(interval)
      }
    }, 300)
  }
  window.onload = waitForButton
}

// Create the button
// const button = document.createElement('button')
// button.classList.add('btn-toggle-sidebar-menu')
// const icon = document.createElement('i')
// icon.classList.add('far', 'fa-bars')
// button.appendChild(icon)
// document.body.appendChild(button)

// button.addEventListener('mouseover', () => {
//   const icon = button.querySelector('i.far')
//   console.log(icon)
//   if (icon) {
//     if (menuItemsVisible.value) {
//       icon.classList.remove('fa-bars', 'fa-chevron-double-right')
//       icon.classList.add('fa-chevron-double-left')
//     } else {
//       icon.classList.remove('fa-bars', 'fa-chevron-double-left')
//       icon.classList.add('fa-chevron-double-right')
//     }
//   }
// })

// document.head.insertAdjacentHTML(
//   'beforeend',
//   `
//     <style>
//     .btn-toggle-sidebar-menu {
//             position: fixed;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
//             width: 50px;
//             height: 50px;
//         }
//         .btn-toggle-sidebar-menu:hover {

//         }
//     </style>
// `
// )

// Get data for selected menu
const selectedModuleItems = ref()
watch(
  () => module_items_list.value[active_module?.value?.name],
  (newValue) => {
    selectedModuleItems.value = newValue || []
  }
)

const scrollToActiveModule = () => {
  nextTick(() => {
    if (moduleItemsRef.value.length > 0 && active_module.value) {
      const activeIndex = modules_list.value.findIndex((m) => m.name === active_module.value.name)
      if (activeIndex !== -1) {
        const activeItem = moduleItemsRef.value[activeIndex]
        if (activeItem) {
          activeItem.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    }
  })
}

watch(active_module, () => {
  scrollToActiveModule()
})

// Function to toggle mobile menu
const toggleMobileMenu = () => {
  mobileMenuOpen.value = !mobileMenuOpen.value
}

// Function to close mobile menu
const closeMobileMenu = () => {
  mobileMenuOpen.value = false
}

// Function to toggle a group's open/closed state
const toggleGroup = (groupIndex) => {
  openGroups.value = openGroups.value.map((isOpen, index) =>
    index === groupIndex ? !isOpen : false
  )
}

// Initialize with first group open
watch(
  selectedModuleItems,
  (newData) => {
    openGroups.value = newData?.map((_, index) => {
      index
    })
    if (openGroups?.value?.length > 0) {
      openGroups.value[0] = true // Open first group by default
    }
  },
  { immediate: true }
)

// Close mobile menu when window is resized to desktop size
const handleResize = () => {
  if (window.innerWidth > 768 && mobileMenuOpen.value) {
    mobileMenuOpen.value = false
  }
}

// Add resize event listener
onMounted(() => {
  window.addEventListener('resize', handleResize)
  scrollToActiveModule()
  // addExternalButton()
})

// Remove resize event listener
onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped>
/* Base Styles */

.g-side-menu {
  background: #ffffff;
  border-right: 1px solid #e6e6e6;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 250;
  transition: all 0.3sease-in-out;
}

body.g-hide-main-menu .dv-app-theme .dv-app-content {
  margin-left: 80px;
}

.sidebar-container {
  display: flex;
  height: 100vh;
  background-color: #f8f9fa;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Mobile Toggle Button */
.mobile-toggle {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
}

.toggle-icon {
  width: 24px;
  height: 24px;
}

/* Mobile Overlay */
.mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 90;
}

/* Default scrollbar - Transparent */
.sidebar-module {
  width: 80px;
  background-color: #2c3e50;
  color: #ecf0f1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
}

/* On hover, scrollbar becomes visible */
.sidebar-module:hover {
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* Firefox */
}

.sidebar-module:hover::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

.g-module-item {
  width: 70px;
  height: 70px;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 5px;
  border-radius: 8px;
  margin-bottom: 12px;
}

.g-module-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.g-module-icon {
  font-size: 20px;
  width: 50px;
  height: 50px;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.g-module-icon i {
  font-size: 20px;
}

.g-module-item.active .g-module-icon {
  background-color: rgba(255, 255, 255, 0.2);
  border-left: 3px solid #3498db;
}

.g-module-item:hover .g-module-icon {
  background-color: rgba(255, 255, 255, 0.1);
}

.g-module-item.active .g-module-title {
  color: white;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.g-module-item:hover .g-module-title {
  color: white;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.g-module-title {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
}

.g-module-icon.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-left: 3px solid #3498db;
}

.g-module-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Second Column: Items */
.sidebar-content {
  width: 260px;
  background-color: #f1f3f5;
  border-right: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  padding: 10px;
  /* margin-top: 48px; */
  background-color: #ffffff;
}

.sidebar-content:hover {
  scrollbar-color: rgba(111, 110, 110, 0.2) transparent;
}

.sidebar-content:hover::-webkit-scrollbar-thumb {
  background: rgba(111, 110, 110, 0.2);
}

.content-title {
  padding: 16px;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  border-bottom: 1px solid #e9ecef;
  position: sticky;
  top: 0;
  z-index: 1;
}

.content-groups {
  padding: 8px 0;
}

.content-group {
  margin-bottom: 2px;
}

.group-header {
  width: 240px;
  padding: 10px 16px;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #3a4d46;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s;
  border-left: 3px solid transparent;
  background-color: rgba(130, 126, 126, 0.03);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-header:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-left-color: #3498db;
}

.group-header.group-open {
  background-color: rgba(0, 0, 0, 0.05);
  border-left-color: #3498db;
}

.group-icon {
  width: 16px;
  height: 16px;
  color: #6c757d;
}

.group-items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.group-items-open {
  max-height: 500px; /* Arbitrary large value to accommodate content */
}

.g-content-link {
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 16px 4px 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s;
}

.g-content-link:hover {
  color: #3498db;
  transform: translateX(5px);
  transition: transform 0.3s ease-in-out;
}

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #adb5bd;
  font-size: 14px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  #navbar-breadcrumbs, .side-menu .modules-menu, .side-menu .side-menu-icons, .side-menu .side-menu-items {
        display: block !important;
    }
  .mobile-toggle {
    display: flex;
  }

  .mobile-overlay {
    display: block;
  }

  .sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    z-index: 95;
    /* transform: translateX(-100%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); */
  }

  .sidebar-container.mobile-open {
    transform: translateX(0);
  }

  .sidebar-module {
    width: 70px;
    flex-shrink: 0;
  }

  .sidebar-content {
    width: calc(100% - 70px);
  }

  .g-module-item {
    width: 60px;
    height: 60px;
  }

  .g-module-icon {
    width: 20px;
    height: 20px;
  }

  .g-module-title {
    font-size: 10px;
  }
}
</style>
